<template>
  <div>
    <custom-modal modal-width="100vw" position="absolute" heiht="100vh">
      <template #header>
        <div class="kk-profile">
          <ul class="kk-profile__list">
            <i class="mdi mdi-close" @click="closeProfileDialog"></i>
            <li>
              <!-- Conditional Sign In/Sign Out Button -->
              <button v-if="isAuthenticated" class="btn__logout" @click="signOut">
                <span class="mdi mdi-logout"></span>
                <span> Sign Out</span>
              </button>
              <button v-else class="btn__login" @click="goToSignIn">
                <span class="mdi mdi-login"></span>
                <span> Sign In </span>
              </button>
            </li>
            <li>
              <!-- Conditional Sub and Unsub Button -->
              <button v-if="subStatus === 'active'" class="btn__login" @click="unsubscribe">
                <span> Unsubscribe </span>
              </button>
              <button v-else class="btn__logout" @click="subscribe">
                <span>Subscribe </span>
              </button>
            </li>
          </ul>

          <div v-if="user" class="kk-profile__general-info">
            <div class="kk-profile__profile-contacts">
              <h2>{{ user.username }}</h2>
              <div class="kk-profile__profile-contact">
                <span class="kk-profile__profile-email">
                  {{ user.email }}
                </span>
                <i v-if="user.email_verified" class="mdi mdi-check kk-profile__contact--verified"></i>
              </div>
            </div>
            <img
              :src="require(`@/assets/user/${userType}.png`)"
              class="kk-profile__profile-img"
              @click="closeProfileDialog"
            />
          </div>
        </div>
        <hr class="kk-profile__profile--hr" />
      </template>
      <template #body>
        <div class="log__text">Log</div>
        <div class="log__wrapper">
          <div v-for="(row, index) in data" :key="index" class="log__wrapper--data">
            <p>{{ row.timestamp }} :</p>
            <p>subscription check Ok,</p>
            <p>pro: {{ row.pro }},</p>
            <p>subscriptionID: {{ row.subID }},</p>
            <p>lastPayment: {{ row.lastPayment }}</p>
          </div>
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { PayPal } from "../../utils/constants";
import CustomModal from "../common/CustomModal.vue";

export default {
  validations() {
    return {
      currentPassword: { required, minLength: minLength(8) },
      newPassword: { required, minLength: minLength(8) },
    };
  },
  components: { CustomModal },
  emits: ["close-popup"],
  data() {
    return {
      user: null,
      showPassword: false,
      currentPassword: "",
      newPassword: "",
      verificationAttr: "",
      verificationCode: "",
      unsubscribeAuth: null,
      data: [],
    };
  },
  computed: {
    userType() {
      if (this.isAuthenticated && this.subStatus === "active") {
        return "subscribed";
      } else if (this.isAuthenticated) {
        return "login";
      } else {
        return "user";
      }
    },
    subscriptionID() {
      return this.$store.getters.getSubID;
    },
    subStatus() {
      return this.$store.getters.getSubStatus;
    },
    userToken() {
      return this.$store.getters.getUserToken;
    },
    isAuthenticated() {
      return this.$store.getters.getAuth == "signed-in" ? true : false;
    },
    userData() {
      return this.$store.getters.getAuthData;
    },
  },
  mounted() {
    this.data = JSON.parse(localStorage.getItem("subLog")) || [];
    this.reloadUser();
  },
  methods: {
    reloadUser() {
      this.user = this.userData ?? null;
    },
    closeProfileDialog() {
      this.$store.dispatch("toggleProfileDialog");
    },
    subscribe() {
      // Call the subscription logic here
      this.closeProfileDialog();
      this.$store.dispatch("setDeleteDialog", {
        show: true,
      });
    },

    // Unsubscribe from PayPal subscription
    async unsubscribe() {
      this.$store.dispatch("setSubStatus", "suspended");
      this.closeProfileDialog();
      // Get PayPal access token (from backend or implement in frontend)
      const accessToken = await this.getPayPalAccessToken();
      // console.log("access token", accessToken, " subid", this.subscriptionID);
      // console.log("sub id", this.subscriptionID);
      // const response = await fetch(
      const response = await fetch(`${PayPal.PayPaSubUrl}/${this.subscriptionID}/cancel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          reason: "User requested to cancel the subscription",
        }),
      });

      if (response.ok) {
        // Remove subscription ID from Cognito attributes
        const user = await Auth.currentAuthenticatedUser();
        // const subid = user.attributes["custom:subscriptionID"];
        const attributes = {
          "custom:pro": "inactive", // Update Pro status
        };
        await Auth.updateUserAttributes(user, attributes);
        this.$store.dispatch("addToast", {
          severity: "success",
          life: 3000,
          summary: "Unsubscribed",
          detail: "You have successfully unsubscribed.",
        });
      } else {
        const errorData = await response.json();
        console.error("Error canceling subscription:", errorData);
        this.$store.dispatch("addToast", {
          severity: "error",
          life: 3000,
          summary: "Unsubscription failed",
          detail: "Failed to unsubscribe. Please try again.",
        });
      }
    },
    // Fetch PayPal access token (should ideally be done securely on backend)
    async getPayPalAccessToken() {
      const clientId = PayPal.clientID;
      const clientSecret = PayPal.SecrectKey;
      // const response = await fetch("https://api-m.paypal.com/v1/oauth2/token", {

      const response = await fetch(PayPal.payPalTokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic " + btoa(`${clientId}:${clientSecret}`),
        },
        body: "grant_type=client_credentials",
      });

      const data = await response.json();
      return data.access_token;
    },

    goToSignIn() {
      this.closeProfileDialog();
      this.$router.push("/auth");
    },
    signOut() {
      this.closeProfileDialog();
      Auth.signOut();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/variables";

.log {
  &__text {
    margin-left: 1rem;
    font-weight: bold;
  }

  &__wrapper {
    padding: 10px;
    border: 2px solid black;
    margin-left: 1rem;
    overflow: auto;
    width: 70%;
    height: 200px;
    &--data {
      display: flex;
      gap: 0.5rem;
    }
  }
}

/* Overall table styling */
.styled-table {
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  border: 1px solid #ddd;
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Header row styling */
.styled-table thead tr {
  // background-color: rgba(8, 50, 119, 0.56);
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

/* Cell padding */
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table th {
  top: 0;
  z-index: 2;
  position: sticky;
  background-color: rgba(8, 50, 119, 1);
}

/* Alternate row colors */
.styled-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

/* Hover effect on rows */
.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Border styling for table rows */
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

/* Last row styling */
.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid rgba(8, 50, 119, 0.56);
}

.kk-profile {
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__general-info {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  &__profile--hr {
    margin: 0.7rem 0px;
    border: 1px solid #0832770a;
  }

  &__profile-img {
    filter: brightness(0) saturate(100%) invert(12%) sepia(62%) saturate(3136%) hue-rotate(210deg) brightness(96%)
      contrast(97%);
    width: 2rem;
    margin-bottom: 1rem;
  }
  &__profile-contact {
    align-self: end;
    // display: flex;
    // align-items: center;
  }
  &__profile-contacts {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 5px;

    h2 {
      font-size: 0.85remx;
      color: #083277;
    }
  }

  &__profile-email {
    // margin: 0 0.25rem;
    font-size: 10px;
    color: rgba(8, 50, 119, 0.56);
  }

  &__contact--verified {
    color: @kk-color-green;
  }

  &__contact--not-verified {
    color: @kk-color-light-gray;
    background-color: @kk-color-gray;
    font-size: 0.65rem;
    padding: 0.0625rem 0.25rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  &__contact-verification {
    display: block;
    margin-top: 1rem;
    width: 100%;

    > form {
      display: flex;
      align-items: center;
    }
  }

  &__list {
    display: flex;
    // flex-direction: column;
    gap: 1rem;
    font-family: Roboto;
    // font-weight: 500;
    list-style: none;
    font-size: 1.1rem;
    padding-left: 12px;
    //  margin-left: 2rem;

    li {
      color: rgba(8, 50, 119, 0.73);
      // font-family: Roboto;
      // font-size: 14px;
      // font-weight: 500;
      display: flex;
      text-align: left;
    }
  }

  &__contact-verification-code {
    flex-grow: 1;
  }
  &__contact-verification-submit-btn {
    margin-left: 0.5rem;
  }

  &__change-password {
    border-top: solid 1px @kk-color-light-gray;
    margin-top: 1rem;
    padding-top: 1rem;
    text-align: center;
  }
  &__toggle-show-password {
    margin-left: 0.5rem;
  }
  &__change-password-submit-btn {
    margin-top: 0.5rem;
  }

  &__action-btns {
    margin-top: 0.75rem;
    text-align: right;
  }
  &__subscribe {
    border-top: solid 1px @kk-color-light-gray;
    margin-top: 0.7rem;
    text-align: center;
  }
}
</style>
