<template>
  <div>
    <div>
      <v-app>
        <v-main>
          <template>
            <router-view></router-view>
            <toasts />
          </template>
        </v-main>
      </v-app>
    </div>
  </div>
</template>

<script>
import StatusTable from "./components/StatusTable";
import { Auth } from "aws-amplify"; // Import AWS Amplify Auth module
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import Amplify, { Hub } from "aws-amplify";
import { navigateTo } from "./router";
import Toasts from "./components/common/Toasts.vue";
import { mapState } from "vuex";
import { PayPal } from "./utils/constants";
import Axios from "axios";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    StatusTable,
    Toasts,
  },
  beforeCreate() {
    const signIn = async (user) => {
      const attrs = user.attributes;
      await this.$store.dispatch("setAuthState", {
        state: "signed-in",
        user: {
          username: user.username,
          email: attrs.email,
          email_verified: attrs.email_verified,
          phone_number: attrs.phone_number,
          phone_number_verified: attrs.phone_number_verified,
        },
      });
      console.log("user sign in", attrs);
      this.$store.dispatch("setSubStatus", attrs["custom:pro"]);
      this.$store.dispatch("addToast", {
        severity: "success",
        life: 3000,
        summary: "Singed in",
        detail: "Successfully singed in",
      });
    };

    const signOut = async () => {
      await this.$store.dispatch("setAuthState", {
        state: "signed-out",
        user: null,
      });
      this.$store.dispatch("addToast", {
        severity: "success",
        life: 3000,
        summary: "Singed out",
        detail: "Successfully singed out",
      });
    };

    this.cancelListener = Hub.listen("auth", async (data) => {
      const { payload } = data;

      if (payload.event == "signIn" || payload.event === "signUp") {
        await signIn(payload.data);
        navigateTo({ path: "/" });
      } else if (payload.event == "signOut") {
        console.log("sign out in hub ");
        await signOut();
        navigateTo({ path: "/" });
      }
    });
  },
  computed: {
    ...mapState(["statusTable"]),
    isToastVisible() {
      return this.statusTable?.showToast;
    },
    subscriptionID() {
      return this.statusTable.authUser?.subID;
    },
    nextBillingTime() {
      return this.$store.getters.getNextBillingtime;
    },
  },
  watch: {
    subscriptionID(newValue, oldValue) {
      if (newValue) {
        setTimeout(async () => {
          const result = await this.getPayPalAccessToken();
          const { status } = result;
          const { next_billing_time } = result.billing_info;
          const { time } = result.billing_info.last_payment;
          const currentTime = new Date().toISOString();
          if (status === "ACTIVE" && currentTime < next_billing_time) {
            const user = await Auth.currentAuthenticatedUser();

            // console.log("user props", user);
            console.log("sub data after approve", subscriptionData);
            const attributes = {
              "custom:lastPayment": new Date(time).toLocaleDateString("en-US"),
            };
            await Auth.updateUserAttributes(user, attributes);
            this.$store.dispatch("setNextBillingTime", { nextBillingTime: next_billing_time });
          }
          console.log("the resutl afte rsub id chagnes", result);
        }, 360000);
      }
    },
  },
  methods: {
    async getPayPalAccessToken() {
      // if (!this.subscriptionID) return;
      if (!this.subscriptionID) return;
      const clientId = PayPal.clientID;
      const clientSecret = PayPal.SecrectKey;

      try {
        // Fetch the access token from PayPal
        const response = await fetch(PayPal.payPalTokenUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Basic " + btoa(`${clientId}:${clientSecret}`),
          },
          body: "grant_type=client_credentials",
        });

        const { access_token } = await response.json(); // Extract access token
        // console.log("Access Token:", access_token);

        // Check the subscription status after obtaining the access token
        const data = await this.checkSubscriptionStatusOnPaypal(access_token, this.subscriptionID);
        console.log("sub data", data);
        const { status } = data;
        const { next_billing_time } = data.billing_info;
        const { time } = data.billing_info.last_payment;

        const currentTime = new Date().toISOString();
        const user = await Auth.currentAuthenticatedUser();
        const attributes = {
          "custom:pro": "inactive", // Update Pro status to inactive
        };

        try {
          // Safely parse the subLog array or initialize it as an empty array
          const subLogArr = JSON.parse(localStorage.getItem("subLog")) || [];

          // Prepare new log data
          const sublogData = {
            timestamp: new Date()
              .toISOString()
              .replace(/[-:.TZ]/g, "")
              .slice(0, 14), // Format: YYYYMMDDHHMMSS
            pro: user.attributes["custom:pro"],
            subID: user.attributes["custom:subscriptionID"],
            lastPayment: new Date(time).toLocaleDateString("en-US"),
          };

          // Add new data to the array
          subLogArr.push(sublogData);

          // Save the updated array back to localStorage
          localStorage.setItem("subLog", JSON.stringify(subLogArr));
        } catch (error) {
          console.error("Failed to parse or update subLog:", error);
          // Optionally, you can reset the localStorage item if it's corrupted:
          localStorage.setItem("subLog", JSON.stringify([]));
        }

        if (status === "CANCELLED" && currentTime < this.nextBillingTime) {
          console.log("sub is canceled");
          this.$store.dispatch("setSubStatus", "suspended");
          await Auth.updateUserAttributes(user, attributes);
        } else if (status === "EXPIRED") {
          console.log("sub is expired");
          this.$store.dispatch("setSubStatus", "inactive");
          await Auth.updateUserAttributes(user, attributes);
        }
        return data;
      } catch (error) {
        console.error("Error fetching PayPal access token or checking subscription status:", error);
      }
    },
    async checkSubscriptionStatusOnPaypal(accessToken, subscriptionID) {
      // console.log("sub id", this.subscriptionID);
      // if (!this.subscriptionID) return;
      try {
        const response = await Axios.get(`${PayPal.PayPaSubUrl}/${subscriptionID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Access token needed to authenticate API requests
          },
        });

        const subscriptionStatus = response.data; // "ACTIVE", "CANCELLED", etc.
        return subscriptionStatus;
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        return null;
      }
    },
  },

  beforeUnmount() {
    this.cancelListener();
  },
  created() {
    document.title = "Earned Value";
  },
  async mounted() {
    setInterval(async () => {
      await this.getPayPalAccessToken(); // Check every 24 hours
    }, 86400000); // 24 hours in milliseconds
  },
};
</script>

<style>
.sc-amplify-sign-in-button {
  display: none !important;
}

button.amplify-sign-in-with-aws {
  display: none !important;
}
</style>
